import React from 'react'

import AboveTheFold from './AboveTheFold'
import OldDogNewTricks from './OldDogNewTricks'
import MakingADifference from './MakingADifference'
import EveryDog from './EveryDog'
import VetEndorsement from 'src/components/shared/VetEndorsement'
import DachshundMarquee from './DachshundMarquee'
import { Singles } from '../OurProducts'

export default React.memo(() => (
  <>
    <AboveTheFold />
    <OldDogNewTricks />
    <MakingADifference />
    <EveryDog />
    <VetEndorsement />
    <DachshundMarquee />
    <Singles title="Our Products" buttonCopy="Shop Now" isProductLibrary />
  </>
))
