import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import DropHeader from 'src/components/shared/DropHeader'
import { Headline, LargeP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.orange};
  position: relative;
  ${media.mobile`
    padding: 40px;
  `}
  ${media.tablet`
    padding: 0 25px 60px 25px;
  `}
  ${media.desktop`
    padding: 0 30px 140px 30px;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  ${media.mobile`
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 20px;
  `}
  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  `}
  ${media.desktop`
    grid-template-columns: repeat(2, 500px);
    margin-top: 40px;
  `}
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.orange};
  ${media.desktop`
    max-width: 515px;
    margin: 0 auto;
  `}
`

const TextGroup = styled.div`
  display: grid;
  grid-row-gap: 16px;
  ${media.mobile`
    padding: 30px 0;
  `}
  ${media.tablet`
    padding: 10px 30px 10px 30px;
    max-width: 500px;
  `}
`

const MobileHide = styled.div`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: inline;
  `}
`

const MobileTitle = styled(Headline)`
  margin-bottom: 20px;
  ${media.tablet`
    display: none;
  `}
`

const query = graphql`
  query {
    bonbon: file(relativePath: { regex: "/about-page/alex-and-bonbon.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
  }
`

const OldDogNewTricks = () => {
  const images = useStaticQuery(query)
  const title = "Hi, I'm Alex, and this lady is Bonbon."
  return (
    <Section>
      <MobileHide>
        <DropHeader title={title} />
      </MobileHide>
      <Grid>
        <ImageContainer>
          <GatsbyImage
            image={getImage(images.bonbon)}
            alt="White dog"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImageContainer>
        <TextGroup>
          <MobileTitle>{title}</MobileTitle>
          <LargeP>Originally, my partners and I founded a wellness company for humans.</LargeP>
          <LargeP>
            I would come home every day from working to give people the healthiest, happiest lives
            possible, and be met at the door by the loving face of my best friend (who isn't getting
            any younger).
          </LargeP>
          <LargeP>
            I couldn't help but realize that BonBon deserved our help just as much as anyone. And
            so, Finn was born.
          </LargeP>
        </TextGroup>
      </Grid>
    </Section>
  )
}

export default OldDogNewTricks
