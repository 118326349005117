import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { LargeP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.pink};
  position: relative;
  ${media.mobile`
    padding: 0px 10px 50px 10px;
  `}
  ${media.tablet`
    padding: 0 10px 50px 30px;
  `}
  ${media.desktop`
    padding: 40px 30px 100px 30px;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  ${media.mobile`
    grid-template-columns: 1fr;
    align-items: center;
    margin-top: 20px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1.25fr;
    justify-content: center;
    grid-gap: 20px;
    margin-top: 40px;
  `}
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TextGroup = styled.div`
  display: grid;
  grid-row-gap: 20px;

  ${media.mobile`
    padding: 0 20px 30px 20px;
  `}
  ${media.tablet`
    padding: 10px 40px 40px 30px;
  `}
  ${media.desktop`
    padding: 60px 60px 60px 40px;
  `}
`

const query = graphql`
  query {
    fosterDogs: file(relativePath: { regex: "/about-page/foster-dogs-images.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 575)
      }
    }
  }
`

const OldDogNewTricks = () => {
  const images = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.making-a-difference__copy',
      { opacity: 0, y: 20 },
      {
        scrollTrigger: '.making-a-difference__copy',
        delay: 0.25,
        opacity: 1,
        y: 0,
        duration: 1,
        ease: Power3.easeOut,
        stagger: 0.2
      }
    )
  }, [])

  const title = 'Committed to Making a Difference'

  return (
    <Section>
      <DropHeader title={title} />
      <Grid>
        <ImageContainer>
          <GatsbyImage
            image={getImage(images.fosterDogs)}
            alt="Instagram screenshots"
            style={{ height: '100%', width: '100%', marginBottom: 30 }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImageContainer>
        <TextGroup>
          <LargeP className="making-a-difference__copy">
            At Finn, we really, really love dogs. And we want to do everything that we can to keep
            our four legged friends happy and healthy.
          </LargeP>
          <LargeP className="making-a-difference__copy">
            So we give back — by partnering with local animal shelters, and donating a portion of
            our proceeds to help with their rescue efforts. And by giving shelters lots of Finn
            supplements — to help keep their doggos healthy.
          </LargeP>
        </TextGroup>
      </Grid>
    </Section>
  )
}

export default OldDogNewTricks
