import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import DropHeader from 'src/components/shared/DropHeader'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  ${media.mobile`
    padding: 20px 0 0 0;
    height: 720px;
  `}
  ${media.tablet`
    height: fit-content;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${media.mobile`
    margin-top: -20px;
    height: 500px;
  `}
  ${media.tablet`
    margin-top: -24px;
    height: 700px;
  `}
  ${media.desktop`
    margin-top: -36px;
    height: 950px;
  `}
`

const query = graphql`
  query {
    aboveTheFold: file(relativePath: { regex: "/about-page/above-the-fold.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1400)
      }
    }
  }
`

const AboveTheFold = () => {
  const images = useStaticQuery(query)

  return (
    <Section>
      <DropHeader title="The Finn Story" isPageHeader />
      <ImageWrapper className="about-hero-image">
        <GatsbyImage
          image={getImage(images.aboveTheFold)}
          alt="Woman and her dog looking at each other"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </ImageWrapper>
    </Section>
  )
}

export default AboveTheFold
