import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import About from 'src/components/About'
import SEO from 'src/components/shared/SEO'

const SEO_CONFIG = {
  title: 'About Us | Finn',
  description:
    'At Finn, we really, really love dogs, and we want to do everything that we can to keep our four-legged friends happy and healthy. This is why we created Finn.',
  keywords: ['About Us']
}

const AboutPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <About />
      </Layout>
    </>
  )
}

export default AboutPage
