import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import StickyBox from 'react-sticky-box'
import { useWindowScroll } from 'react-use'

import DropHeader from '../shared/DropHeader'
import { Headline, LargeP, P, MonoP } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  height: fit-content;
  position: relative;
  ${media.mobile`
    padding: 0 0 20px 0;
  `}
  ${media.tablet`
    padding: 0 0 80px 0;
  `}
  ${media.desktop`
    padding: 0 0 200px 0;
  `}
`

const StyledLargeP = styled(LargeP)`
  text-align: center;
  ${media.mobile`
    max-width: 300px;
    margin: 40px auto 0 auto;
  `}
  ${media.tablet`
    max-width: 525px;
    margin: 40px auto 0 auto;
  `}
  ${media.desktop`
    margin: 70px auto 0 auto;
  `}
`

const Grid = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    max-width: 1440px;
    padding: 100px 0 0 30px;
  `}
`

const ActionBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 20px 10px 20px;
  position: relative;
  ${media.desktop`
    display: none;
  `}
`

const ActionText = styled(MonoP)`
  color: ${colors.slate};
`

const NextButton = styled(ActionText)`
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  &:hover {
    color: ${colors.navy};
  }
`

const PrevButton = styled(NextButton)`
  right: auto;
  left: 10px;
`

const MobileGrid = styled(Grid)`
  ${media.mobile`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: 0;
  `}
  ${media.tablet`
    width: 650px;
    margin: 0 auto;
  `}
  ${media.desktop`
    display: none;
  `}
`

const LeftGroup = styled(StickyBox)`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 30px;
`

const RightGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 80px;
  height: fit-content;
`

const LabelCopy = styled(Headline)`
  color: ${(props) => (props.active ? colors.navy : colors.lightText)};
  transition: 200ms color ease-in-out;
  line-height: 1 !important;
`

const ArrowIcon = styled(LabelCopy)`
  ${media.desktop`
    font-size: 60px;
    padding-left: 40px;
    transition: 200ms all ease-in-out;
  `}
`

const LeftRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => (props.active ? '100px' : '0px')} 490px;
  transition: 200ms all ease-in-out;
  & ${ArrowIcon} {
    color: ${(props) => (props.active ? colors.navy : colors.lightText)};
    opacity: ${(props) => (props.active ? '1' : '0')};
    width: ${(props) => (props.active ? 'fit-content' : '0')};
  }
`

const MobileTextGroup = styled.div`
  ${media.mobile`
    height: 400px;
    padding: 40px 20px;
  `}
  ${media.tablet`
    height: 200px;
    padding: 40px 0 20px 0;
  `}
`

const ImgDescription = styled(P)`
  padding: 12px 40px 0 0;
`

const BigArrow = styled.span`
  font-size: 20px;
`

const query = graphql`
  query {
    scienceBased: file(relativePath: { regex: "/about-page/science-based.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 575)
      }
    }
    bestQuality: file(relativePath: { regex: "/about-page/best-quality.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 575)
      }
    }
    unique: file(relativePath: { regex: "/about-page/unique.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 575)
      }
    }
    withYou: file(relativePath: { regex: "/about-page/with-you.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 575)
      }
    }
  }
`

const EveryDog = () => {
  const images = useStaticQuery(query)
  const scrollRef = useRef(null)
  const [active, setActive] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    setActive(0)
    gsap.registerPlugin(ScrollTrigger)
    const to = { opacity: 1, x: 0, duration: 0.5, ease: Power3.easeOut, delay: 0 }
    gsap.fromTo(
      '.EveryDog_LeftGroup_0',
      { opacity: 0, x: -300 },
      { scrollTrigger: '.EveryDog_LeftGroup_0', ...to }
    )
    gsap.fromTo(
      '.EveryDog_LeftGroup_1',
      { opacity: 0, x: -300 },
      { scrollTrigger: '.EveryDog_LeftGroup_1', ...to }
    )
    gsap.fromTo(
      '.EveryDog_LeftGroup_2',
      { opacity: 0, x: -300 },
      { scrollTrigger: '.EveryDog_LeftGroup_2', ...to }
    )
    gsap.fromTo(
      '.EveryDog_LeftGroup_3',
      { opacity: 0, x: -300 },
      { scrollTrigger: '.EveryDog_LeftGroup_3', ...to }
    )
  }, [])

  const items = [
    {
      copy: 'Science-based, Results Driven',
      img: getImage(images.scienceBased),
      alt: 'Picture of Finn products',
      description:
        'Choosing the right solution for your pet can be confusing, which is why we put extra care into the formulation of our products and only use scientifically backed, and thoroughly vetted ingredients to help you proactively care for your pet. We also partner with vets and pet care providers to make sure everything we do has their seal of approval.'
    },
    {
      copy: 'The Very Best Quality',
      img: getImage(images.bestQuality),
      alt: 'Woman talking to dog',
      description:
        "Our pets don't just deserve the highest quality ingredients, their health demands it. Whether it's sourcing ingredients or dishing out care tips, we'll never compromise or cut corners—because your pet's wellbeing isn't something we're willing to risk."
    },
    {
      copy: 'Every Pet is Unique',
      img: getImage(images.unique),
      alt: 'Dog sitting on couch',
      description:
        "Every pup is unique, which is why we'll never treat two dogs alike. We'll factor in characteristics like breed, age, weight, lifestyle, and mood to make sure you're getting more than just a one-size-fits-all solution."
    },
    {
      copy: "We're With You All The Way",
      img: getImage(images.withYou),
      alt: "We're With You All The Way",
      description:
        "We're here for the life of your pet and are committed to being your partner in their health journey. Our products are developed to support dogs through different stages of life, and we're committed to continue innovating on pet wellness as the health journeys of our pets evolve."
    }
  ]

  useWindowScroll()
  const el = scrollRef.current && scrollRef.current.getBoundingClientRect()
  const scrollTop = el && -el.top

  // Used to toggle between labels on left
  const img1Ref = useRef(null)
  const img2Ref = useRef(null)
  const img3Ref = useRef(null)
  const img1Bottom = img1Ref.current && img1Ref.current.getBoundingClientRect().bottom
  const img2Bottom = img2Ref.current && img2Ref.current.getBoundingClientRect().bottom
  const img3Bottom = img3Ref.current && img3Ref.current.getBoundingClientRect().bottom

  const label2Ref = useRef(null)
  const label3Ref = useRef(null)
  const label4Ref = useRef(null)
  const label2Top = label2Ref.current && label2Ref.current.getBoundingClientRect().y
  const label3Top = label3Ref.current && label3Ref.current.getBoundingClientRect().y
  const label4Top = label4Ref.current && label4Ref.current.getBoundingClientRect().y

  useEffect(() => {
    if (scrollTop) {
      setActive(0)
      if (img1Bottom < label2Top) setActive(1)
      if (img2Bottom < label3Top) setActive(2)
      if (img3Bottom < label4Top) setActive(3)
    }
  }, [scrollTop, img1Bottom, img2Bottom, img3Bottom, label2Top, label3Top, label4Top])

  const handleActiveChange = (idx: number) => {
    if (!isAnimating) {
      setIsAnimating(true)
      setActive(idx)
      const exitX = idx > active ? -200 : 200
      gsap.fromTo(
        '.animatedGrid',
        { opacity: 1, x: 0 },
        { duration: 0.25, delay: 0, ease: Power3.easeOut, opacity: 0, x: exitX }
      )
      gsap.fromTo(
        '.animatedGrid',
        { opacity: 0, x: 0 },
        { duration: 1, delay: 0.25, ease: Power3.easeIn, opacity: 1, x: 0 }
      )
      setTimeout(() => setIsAnimating(false), 600)
    }
  }

  const title = 'Every Dog Has\nIts Day'
  const lineBreakTitle = 'Every Dog\nHas Its Day'

  const imgRefs = [img1Ref, img2Ref, img3Ref, null]
  const labelRefs = [null, label2Ref, label3Ref, label4Ref]

  return (
    <Section>
      <DropHeader title={title} titleTablet={lineBreakTitle} titleMobile={lineBreakTitle} />
      <StyledLargeP>
        And Finn wants to make those days a lot better. How do we do that? By sticking to our
        values:
      </StyledLargeP>
      <Grid ref={scrollRef}>
        <LeftGroup offsetTop={140} offsetBottom={20}>
          {items.map((label, idx) => (
            <LeftRow
              active={active === idx}
              key={label.copy}
              className={`EveryDog_LeftGroup_${idx}`}
              ref={labelRefs[idx]}
            >
              <ArrowIcon>→</ArrowIcon>
              <LabelCopy active={active === idx}>{label.copy}</LabelCopy>
            </LeftRow>
          ))}
        </LeftGroup>
        <RightGroup ref={scrollRef}>
          {items.map((item, idx) => {
            return (
              <div key={item.alt} ref={imgRefs[idx]}>
                <GatsbyImage image={item.img} alt={item.alt} style={{ height: 375 }} />
                <ImgDescription>{item.description}</ImgDescription>
              </div>
            )
          })}
        </RightGroup>
      </Grid>
      <MobileGrid>
        <ActionBar>
          {active > 0 && (
            <PrevButton onClick={() => handleActiveChange(active - 1)}>
              <BigArrow>￩</BigArrow> PREV
            </PrevButton>
          )}
          <ActionText>
            {active + 1} / {items.length}
          </ActionText>
          {active + 1 < items.length && (
            <NextButton onClick={() => handleActiveChange(active + 1)}>
              NEXT <BigArrow>￫</BigArrow>
            </NextButton>
          )}
        </ActionBar>
        <div className="animatedGrid">
          <GatsbyImage
            image={items[active].img}
            alt={items[active].alt}
            style={{ width: '100%', height: 275 }}
          />
          <MobileTextGroup>
            <Headline>{items[active].copy}</Headline>
            <br />
            <LargeP>{items[active].description}</LargeP>
          </MobileTextGroup>
        </div>
      </MobileGrid>
    </Section>
  )
}

export default EveryDog
