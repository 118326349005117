import React from 'react'
import styled, { keyframes } from 'styled-components'

import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import DachshundIcon from '../../assets/img/dog-icons/dachshund-left.svg'

const marqueeAnimation = keyframes`
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
`

const Marquee = styled.div`
  background-color: ${colors.pink};
  color: ${colors.orange};
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  ${media.mobile`
    height: 165px;
  `}
  ${media.desktop`
    height: 265px;
  `}
`

const DogIcon = styled.img`
  width: auto;
  margin-left: 25%;
  animation: ${marqueeAnimation} 10s linear infinite;
  ${media.mobile`
    height: 165px;
  `}
  ${media.desktop`
    height: 200px;
  `}
`

const DachshundMarquee = () => {
  return (
    <Marquee>
      <DogIcon src={DachshundIcon} alt="Dachshund icon" />
      <DogIcon src={DachshundIcon} alt="Dachshund icon" />
    </Marquee>
  )
}

export default DachshundMarquee
